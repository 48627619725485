<template>
    <div
        class="reset-success-index"
        :style="{
            backgroundImage:
                'url(' +
                $assets.auth.login.fem +
                ')' +
                ', url(' +
                $assets.auth.login.masc +
                ')' +
                ', url(' +
                $assets.auth.login.city +
                ')',
        }"
    >
        <div class="reset-success">
            <picture class="reset-success__image">
                <img :src="$assets.auth.recovery.success" alt="image" />
            </picture>
            <h2 class="reset-success__title">
                {{ $lang.pages.contrasenaActualizadaExitosamente.success }}
            </h2>
            <p class="reset-success__subtitle">
                {{ $lang.pages.contrasenaActualizadaExitosamente.enjoy }}
            </p>
            <div class="reset-success__buttons">
                <ButtonGeneric
                    class="reset-success__button"
                    :info="{
                        text: $lang.pages.contrasenaActualizadaExitosamente.see_coupons,
                        icon: $assets.white.couponsMenu,
                        link: '/',
                    }"
                />
                <ButtonGeneric
                    class="reset-success__button"
                    :info="{
                        text: $lang.pages.contrasenaActualizadaExitosamente.see_offers,
                        icon: $assets.white.offersMenu,
                        link: `/${$lang.routes.offers}`,
                    }"
                />
            </div>
            <div class="reset-success__countdown">
                <p class="reset-success__countdown-tittle">
                    {{ $lang.pages.contrasenaActualizadaExitosamente.redirect }}
                </p>
                <p class="reset-success__countdown-count">{{ countDown }}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
definePageMeta({
    middleware: ['not-auth-required'],
})

const countDown = ref(5)

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

const { $lang } = useNuxtApp()

const title = $lang.pages.contrasenaActualizadaExitosamente.title_seo
const description = $lang.pages.contrasenaActualizadaExitosamente.description_seo
useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
const Router = useRouter()

onMounted(() => {
    const timer = setInterval(() => {
        countDown.value--
        if (countDown.value === 0) {
            clearInterval(timer)

            Router.push('/' + $lang.routes.login)
        }
    }, 1000)
})
</script>

<style lang="postcss" scoped>
.reset-success-index {
    @apply container bg-no-repeat pb-5 lg:pb-10;
    background-size: 40%, 40%, contain;
    background-position:
        0% 95%,
        100% 95%,
        center bottom;

    .reset-success {
        @apply relative mx-auto mt-3 max-w-xl space-y-3 rounded-lg bg-white bg-opacity-95 p-5 xs:mt-20;
        &__image {
            @apply block h-40;
            img {
                @apply mx-auto h-full;
            }
        }
        &__title {
            @apply text-center text-3xl font-medium text-gray-800;
        }
        &__subtitle {
            @apply text-center text-xl text-gray-800;
        }
        &__buttons {
            @apply mb-5 space-y-2 md:flex md:flex-wrap md:justify-center md:space-x-5 md:space-y-0;
        }
        &__button {
            @apply bg-site-primary text-white lg:transition-colors lg:hover:bg-site-primary-hover;
        }
        &__countdown {
            @apply space-y-4 text-center;
        }
        &__countdown-title {
            @apply text-lg text-gray-800;
        }
        &__countdown-count {
            @apply text-6xl text-site-primary;
        }
    }
}
</style>
